import { CustomIcon } from '@/components/icons/types';

const Infiniti: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9997 8.70001C7.16336 8.70001 0 12.3119 0 16.766C0 20.3729 4.69615 23.427 11.1762 24.4589L15.9997 12.936L20.8238 24.4589C27.3026 23.427 32 20.3729 32 16.766C32 12.3119 24.8354 8.70001 15.9997 8.70001ZM23.3465 20.54L15.9997 12.7004L8.68 20.5168C5.07562 19.5932 2.69981 17.9747 2.69981 15.9861C2.69981 12.8313 8.61129 9.87335 15.9877 9.87335C23.3641 9.87335 29.4143 12.8313 29.4143 15.9861C29.4143 17.9897 27.0019 19.6187 23.349 20.5394L23.3465 20.54Z"
      fill="currentColor"
    />
  </svg>
);

export default Infiniti;
