import { CustomIcon } from '@/components/icons/types';

const Lexus: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7439 6.54644C16.8272 6.42438 16.8808 6.07034 16.377 6.0564L16.0337 6.04254C8.55889 6.04254 2.47473 10.7173 2.47473 16.4563C2.47473 23.1286 8.55993 26.8452 16.0337 26.8452C20.1342 26.8452 24.0987 25.6688 26.5734 23.4678L29.2535 23.3597C26.3523 26.3939 21.3939 28.3073 15.9812 28.3073C7.13554 28.3073 0.371971 24.2902 0 16.5118C0.150729 10.0318 6.69817 4.81063 15.9812 4.77295C18.0314 4.77295 20.0538 4.98917 21.856 5.52183C22.472 5.68547 22.5563 6.17941 22.3768 6.42438C21.4325 7.75054 16.6219 13.3418 13.8606 18.2337C13.6433 18.6294 13.5738 18.8913 13.6572 19.2067C13.7663 19.7384 13.9835 19.9834 14.655 19.9973C19.8614 20.1341 22.8013 20.0528 27.9938 19.9417C28.7179 19.9308 28.9669 19.6016 29.1692 19.0282C29.4419 18.2059 29.5928 17.345 29.5928 16.4563C29.5928 12.5056 27.2837 8.81681 22.8152 6.96995L23.6335 5.92057C28.7318 7.88645 32 11.974 32 16.5118C32 18.5491 31.2898 20.8582 30.0579 22.1427C29.9349 22.2925 29.5928 22.5404 29.2248 22.5415C22.8529 22.7577 19.9983 22.9501 12.8775 22.7299C11.9184 22.716 11.359 22.7051 10.4703 22.3491C9.11936 21.8025 8.44688 20.529 8.44688 19.4518C8.44688 18.6637 8.75719 18.1332 9.17552 17.4181L9.1858 17.4006C11.7439 12.9321 15.2571 8.85943 16.7439 6.54644Z"
      fill="currentColor"
    />
  </svg>
);

export default Lexus;
