import { CustomIcon } from '@/components/icons/types';

const Kia: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5645 19.4408C18.5645 19.5016 18.5837 19.5368 18.6189 19.5368C18.6477 19.5336 18.6733 19.5208 18.6957 19.5048L28.6691 13.0319C28.8377 12.9141 29.0395 12.8535 29.2451 12.8591H31.4369C31.7728 12.8591 32 13.083 32 13.419V17.6042C32 18.1097 31.888 18.4041 31.4401 18.6729L28.7811 20.2727C28.7546 20.2911 28.7237 20.3022 28.6915 20.3047C28.6595 20.3047 28.6275 20.2791 28.6275 20.1863V15.3036C28.6275 15.246 28.6115 15.2076 28.5731 15.2076C28.545 15.2109 28.5184 15.222 28.4964 15.2396L21.2075 19.9752C21.0411 20.0871 20.8459 20.1479 20.6475 20.1447H15.816C15.4769 20.1447 15.2529 19.9208 15.2529 19.5816V13.5502C15.2529 13.5054 15.2337 13.4638 15.1953 13.4638C15.1673 13.4674 15.1407 13.4784 15.1185 13.4958L10.3094 16.3819C10.2614 16.4139 10.2486 16.4363 10.2486 16.4587C10.2486 16.4779 10.2582 16.4971 10.2902 16.5323L13.7266 19.9656C13.7714 20.0104 13.8034 20.0456 13.8034 20.0839C13.8034 20.1159 13.7522 20.1415 13.6914 20.1415H10.5877C10.3446 20.1415 10.1558 20.1063 10.0246 19.9752L7.94161 17.8954C7.93371 17.8859 7.92395 17.8782 7.91294 17.8727C7.90193 17.8672 7.8899 17.864 7.87761 17.8634C7.85503 17.8644 7.83305 17.871 7.81362 17.8826L4.33237 19.9752C4.12119 20.1031 3.9772 20.1383 3.76922 20.1383H0.566343C0.223978 20.1415 0 19.9144 0 19.5784V15.4732C0 14.9645 0.111989 14.6733 0.563144 14.4045L3.23808 12.7983C3.25906 12.7845 3.2834 12.7768 3.30847 12.7759C3.35007 12.7759 3.37246 12.8175 3.37246 12.9103V18.4073C3.37246 18.4649 3.38526 18.4905 3.42686 18.4905C3.45565 18.4873 3.48125 18.4745 3.50685 18.4585L12.5427 13.0223C12.7155 12.9039 12.9267 12.8463 13.1379 12.8559H18.0014C18.3374 12.8559 18.5645 13.0798 18.5645 13.4158L18.5613 19.4408H18.5645Z"
      fill="currentColor"
    />
  </svg>
);

export default Kia;
