import { CustomIcon } from '@/components/icons/types';

const MercedesBenz: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1.2334C7.70886 1.2334 1 7.94226 1 16.2334C1 24.5245 7.70886 31.2334 16 31.2334C24.2911 31.2334 31 24.5245 31 16.2334C31 7.94226 24.2911 1.2334 16 1.2334ZM4.44388 9.59143C6.79682 5.51101 11.1453 2.97937 15.8512 2.9198L14.0344 15.0121L4.38431 22.8156C2.06116 18.7054 2.06116 13.6718 4.44388 9.59143ZM16.0597 29.6364C11.3538 29.6662 6.97554 27.1941 4.53325 23.1137L16.0001 18.378L27.5265 23.0541C25.114 27.1048 20.7656 29.6066 16.0597 29.6364ZM27.6754 22.7262L17.9062 15.1611L16.1788 2.89005C20.8846 2.94961 25.1735 5.48126 27.5265 9.56168C29.9092 13.6421 29.9688 18.616 27.6754 22.7262Z"
      fill="currentColor"
    />
  </svg>
);

export default MercedesBenz;
