import { CustomIcon } from '@/components/icons/types';

const Suzuki: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M23.581 15.1583C23.581 15.1583 22.2517 15.5424 19.6803 17.1226C17.375 18.5416 16.471 19.495 16.471 19.495L9.35913 14.58C9.35913 14.58 12.3511 12.3797 14.8511 14.0649L18.2047 16.3654L18.6137 16.0963L9.36057 9.61361C9.36057 9.61361 10.6904 9.229 13.2604 7.6498C15.5671 6.2303 16.471 5.27645 16.471 5.27645L23.5825 10.1915C23.5825 10.1915 20.591 12.3917 18.0905 10.7065L14.7369 8.40559L14.3279 8.67415L23.581 15.1583Z"
      fill="currentColor"
    />
    <path
      d="M3.25405 22.7943C3.07086 22.7943 2.52799 22.8063 2.52799 23.1727C2.52799 23.4069 3.05446 23.4704 3.6962 23.5479C3.79807 23.5602 3.90285 23.5728 4.00888 23.5865C5.86479 23.8239 6.3151 24.4915 6.3151 25.1475C6.3151 25.8232 5.73722 26.7118 3.56049 26.7118C1.19721 26.7118 0.495605 26.1105 0.495605 25.0698H2.72269C3.01714 25.594 3.37346 25.6252 3.56385 25.6252C3.75567 25.6252 4.27648 25.6031 4.27648 25.2732C4.27648 24.8889 3.58721 24.8685 3.29983 24.86C3.25402 24.8587 3.21843 24.8576 3.19746 24.8555C1.41637 24.6656 0.597273 24.3558 0.597273 23.2983C0.597273 22.7248 0.984759 21.7263 3.26028 21.7263C5.30897 21.7263 6.1554 22.4366 6.1554 23.3295H4.13309C3.85158 22.8677 3.43772 22.7943 3.25405 22.7943Z"
      fill="currentColor"
    />
    <path
      d="M12.1923 24.9515C12.1923 26.1706 11.638 26.7235 9.47322 26.7235C7.30943 26.7235 6.75506 26.1706 6.75506 24.9515V21.8631H8.46997V24.7592C8.46997 25.2532 8.86273 25.5452 9.47322 25.5452C10.0602 25.5452 10.4774 25.2546 10.4774 24.7669V21.8631H12.1923V24.9515Z"
      fill="currentColor"
    />
    <path
      d="M12.6602 21.8632H18.073V22.8732L15.3289 25.3938H18.0629V26.6023H12.5681V25.5938L15.2748 23.0669H12.6602V21.8632Z"
      fill="currentColor"
    />
    <path
      d="M21.2154 26.7235C23.3802 26.7235 23.9346 26.1706 23.9346 24.9515V21.8631H22.2192V24.7669C22.2192 25.2546 21.8015 25.5452 21.2154 25.5452C20.605 25.5452 20.2108 25.2532 20.2108 24.7592V21.8631H18.4963V24.9515C18.4963 26.1706 19.0507 26.7235 21.2154 26.7235Z"
      fill="currentColor"
    />
    <path
      d="M30.7073 21.8632H32.4956V26.6023H30.7073V21.8632Z"
      fill="currentColor"
    />
    <path
      d="M30.4349 21.8632H28.0821L26.1783 23.4985V21.8632H24.3895V26.6023H26.1783V24.9214L27.9464 26.6023H30.349L27.734 24.1709L30.4349 21.8632Z"
      fill="currentColor"
    />
  </svg>
);

export default Suzuki;
