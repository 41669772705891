import { CustomIcon } from '@/components/icons/types';

const Polestar: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7937 0.940711V15.9504H30.9651L31.5317 15.3848L18.9176 12.8385L16.3602 0.233398L15.7937 0.940711ZM15.6521 31.5261V16.5164H0.622232L0.0556641 17.082L12.5358 19.6283L15.2271 32.2334L15.6521 31.5261Z"
      fill="currentColor"
    />
  </svg>
);

export default Polestar;
