import { CustomIcon } from '@/components/icons/types';

const Canoo: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8344 6.16197C21.8344 6.03045 21.941 5.92383 22.0726 5.92383H22.9953C23.1269 5.92383 23.2335 6.03045 23.2335 6.16197V10.4485C23.2335 10.58 23.1269 10.6866 22.9953 10.6866H22.0726C21.941 10.6866 21.8344 10.58 21.8344 10.4485V6.16197ZM0 16.4612C0 16.5927 0.106619 16.6993 0.23814 16.6993H4.52465C4.65617 16.6993 4.76279 16.5927 4.76279 16.4612V15.5384C4.76279 15.4069 4.65617 15.3003 4.52465 15.3003H0.238139C0.106619 15.3003 0 15.4069 0 15.5384V16.4612ZM27.2372 16.4612C27.2372 16.5927 27.3438 16.6993 27.4753 16.6993H31.7619C31.8934 16.6993 32 16.5927 32 16.4612V15.5384C32 15.4069 31.8934 15.3003 31.7619 15.3003H27.4753C27.3438 15.3003 27.2372 15.4069 27.2372 15.5384V16.4612ZM22.0726 21.3136C21.941 21.3136 21.8344 21.4202 21.8344 21.5517V25.8382C21.8344 25.9698 21.941 26.0764 22.0726 26.0764H22.9953C23.1269 26.0764 23.2335 25.9698 23.2335 25.8382V21.5517C23.2335 21.4202 23.1269 21.3136 22.9953 21.3136H22.0726ZM8.43907 6.16197C8.43907 6.03045 8.54569 5.92383 8.67721 5.92383H9.6C9.73152 5.92383 9.83814 6.03045 9.83814 6.16197V10.4485C9.83814 10.58 9.73152 10.6866 9.6 10.6866H8.67721C8.54569 10.6866 8.43907 10.58 8.43907 10.4485V6.16197ZM8.67721 21.3136C8.54569 21.3136 8.43907 21.4202 8.43907 21.5517V25.8382C8.43907 25.9698 8.54569 26.0764 8.67721 26.0764H9.6C9.73152 26.0764 9.83814 25.9698 9.83814 25.8382V21.5517C9.83814 21.4202 9.73152 21.3136 9.6 21.3136H8.67721Z"
      fill="currentColor"
    />
  </svg>
);

export default Canoo;
