import { CustomIcon } from '@/components/icons/types';

const Rimac: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.5348 1.74857C24.5728 0.637693 20.2607 -0.00813612 16.002 7.74065e-05C11.7433 -0.00813612 7.43117 0.637693 3.46506 1.74857C3.18888 1.82906 2.99586 2.08122 2.99586 2.37075C2.98765 12.9744 6.97429 26.1006 15.6406 31.8922C15.8572 32.0359 16.1426 32.0359 16.3593 31.8922C25.0297 26.1006 29.0122 12.9785 29.004 2.37075C29.0081 2.08122 28.8151 1.82865 28.5348 1.74857ZM21.2001 22.7321C19.8397 25.0421 18.2042 27.0832 16.271 28.6796C16.1139 28.808 15.886 28.808 15.7289 28.6796C10.4394 24.3162 7.35519 16.6119 6.18066 9.19207L4.83261 8.47027H14.8069C18.0112 8.47027 18.4198 9.5175 18.4198 10.267C18.4198 11.0134 18.0112 12.0596 14.8069 12.0596H11.8911C11.7792 12.0596 11.7063 12.152 11.7063 12.2485C11.7063 12.2928 11.7227 12.3368 11.7587 12.3769L22.579 23.1971L21.2002 22.728L21.2001 22.7321ZM22.9804 19.1982L17.1529 13.3717C17.0687 13.2875 17.1211 13.1469 17.2371 13.1346L18.3726 13.0144C20.7781 12.7536 22.158 11.5503 22.158 10.0349C22.158 8.28547 20.3048 7.29984 17.2012 7.29984H5.92398C5.81105 6.3286 5.72686 5.37074 5.68271 4.42823C5.66727 4.11916 5.87573 3.84606 6.17244 3.77419C9.41678 3.02783 12.7412 2.63563 15.9137 2.63563H16.0862C19.2586 2.63563 22.5831 3.02372 25.8274 3.77419C26.1282 3.84565 26.3366 4.11916 26.3212 4.42823C26.08 9.23207 24.9937 14.5268 22.9804 19.1982Z"
      fill="currentColor"
    />
  </svg>
);

export default Rimac;
