import { CustomIcon } from '@/components/icons/types';

const Maserati: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8211 29.9571C12.8211 29.5535 12.8211 29.1499 12.8211 28.7464C13.8808 28.7464 14.9404 28.7464 16 28.7464C17.0596 28.7464 18.1194 28.7464 19.1791 28.7464C19.1791 29.1499 19.1791 29.5535 19.1791 29.9571C18.1194 29.9571 17.0598 29.9571 16.0001 29.9571C14.9405 29.9571 13.8808 29.9571 12.8211 29.9571Z"
      fill="currentColor"
    />
    <path
      d="M16.0001 32.4648C16.6682 32.4648 17.3362 32.4648 18.0043 32.4648C18.0043 31.8377 18.0043 31.2106 18.0043 30.5835C17.3362 30.5835 16.6682 30.5835 16.0001 30.5835C15.3321 30.5835 14.6639 30.5835 13.9959 30.5835C13.9959 31.2106 13.9959 31.8377 13.9959 32.4648C14.6639 32.4648 15.3321 32.4648 16.0001 32.4648Z"
      fill="currentColor"
    />
    <path
      d="M13.9959 28.12C13.9901 27.0812 13.7128 26.0673 12.8613 25.377C13.9075 25.377 14.9538 25.377 16 25.377C17.0463 25.377 18.0926 25.377 19.1389 25.377C18.2873 26.0673 18.01 27.0812 18.0043 28.12C17.3362 28.12 16.6682 28.12 16.0001 28.12C15.3321 28.12 14.6639 28.12 13.9959 28.12Z"
      fill="currentColor"
    />
    <path
      d="M8.68698 23.3792C8.68698 23.0813 8.68698 22.7834 8.68698 22.4854C11.1246 22.4854 13.5623 22.4854 16 22.4854C18.4377 22.4854 20.8755 22.4854 23.3132 22.4854C23.3132 22.7834 23.3132 23.0813 23.3132 23.3792C20.8755 23.3792 18.4378 23.3792 16.0001 23.3792C13.5624 23.3792 11.1247 23.3792 8.68698 23.3792Z"
      fill="currentColor"
    />
    <path
      d="M9.34137 26.6014C10.1952 26.4229 11.1167 25.9557 11.7276 25.377C10.8798 25.377 10.0321 25.377 9.18437 25.377C9.2367 25.7851 9.28903 26.1932 9.34137 26.6014Z"
      fill="currentColor"
    />
    <path
      d="M22.6588 26.6014C21.805 26.4229 20.8835 25.9557 20.2726 25.377C21.1204 25.377 21.9681 25.377 22.8158 25.377C22.7635 25.7851 22.7112 26.1932 22.6588 26.6014Z"
      fill="currentColor"
    />
    <path
      d="M8.44038 20.5249C7.63878 15.8941 6.09147 11.0722 4.38818 7.90059C6.21642 8.86867 8.18164 10.4044 10.2838 12.5078C9.41176 12.2784 8.5397 12.0983 7.66765 11.9674C8.88346 14.7715 9.88791 17.6337 10.801 20.5249C10.0141 20.5249 9.22722 20.5249 8.44038 20.5249Z"
      fill="currentColor"
    />
    <path
      d="M23.5598 20.5249C24.3614 15.8941 25.9087 11.0722 27.6119 7.90059C25.7837 8.86867 23.8185 10.4044 21.7163 12.5078C22.5884 12.2784 23.4604 12.0983 24.3325 11.9674C23.1167 14.7715 22.1122 17.6337 21.1991 20.5249C21.986 20.5249 22.7729 20.5249 23.5598 20.5249Z"
      fill="currentColor"
    />
    <path
      d="M7.91164 22.0194C7.91164 21.7176 7.91164 21.4159 7.91164 21.114C10.6078 21.114 13.3039 21.114 16 21.114C18.6962 21.114 21.3924 21.114 24.0885 21.114C24.0885 21.4159 24.0885 21.7176 24.0885 22.0194C21.3924 22.0194 18.6963 22.0194 16.0001 22.0194C13.304 22.0194 10.6078 22.0194 7.91164 22.0194Z"
      fill="currentColor"
    />
    <path
      d="M13.2743 7.41115C14.5231 5.31516 15.5949 2.93267 16.0001 0.464844C16.4052 2.93268 17.4771 5.31516 18.7259 7.41115C18.2812 7.21534 17.8101 7.00893 17.2881 6.78291C16.8361 6.58716 16.6439 6.70265 16.6592 7.1571C16.8095 11.6088 17.4805 16.6703 19.8626 20.5249C18.9272 20.5249 17.9919 20.5249 17.0565 20.5249C16.6169 19.4995 16.2648 18.4874 16.0001 17.4887C15.7354 18.4874 15.3832 19.4995 14.9437 20.5249C14.0083 20.5249 13.0729 20.5249 12.1376 20.5249C14.5196 16.6703 15.1906 11.6088 15.3409 7.1571C15.3563 6.70265 15.1641 6.58716 14.712 6.78291C14.1901 7.00893 13.7189 7.21534 13.2743 7.41115Z"
      fill="currentColor"
    />
    <path
      d="M7.91164 24.7506C7.91164 24.4488 7.91164 24.147 7.91164 23.8452C10.6078 23.8452 13.3039 23.8452 16 23.8452C18.6962 23.8452 21.3924 23.8452 24.0885 23.8452C24.0885 24.147 24.0885 24.4488 24.0885 24.7506C21.3924 24.7506 18.6963 24.7506 16.0001 24.7506C13.304 24.7506 10.6078 24.7506 7.91164 24.7506Z"
      fill="currentColor"
    />
  </svg>
);

export default Maserati;
