import { CustomIcon } from '@/components/icons/types';

const NuRide: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <g clipPath="url(#clip0_3158_2081)">
      <path
        d="M20.5608 29.3712H6.34024C5.79921 29.3712 5.25817 28.908 5.10239 28.367L0.233074 16.9274C-0.0763893 16.3085 -0.0763893 15.6916 0.233074 15.1506L5.0245 3.71098C5.25817 3.09205 5.87499 2.7068 6.49392 2.62891H11.0538V18.241H15.8452L20.5608 29.3712ZM11.4411 2.62891H25.6638C26.3585 2.62891 26.668 3.16994 26.8996 3.71098L31.7689 15.0727C32.0783 15.6916 32.0783 16.3106 31.7689 16.8516L26.9775 28.2891C26.7459 28.908 26.127 29.2954 25.508 29.3712H20.9482V13.7591H16.1568L11.4411 2.62891Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3158_2081">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0.000976562)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default NuRide;
